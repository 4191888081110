import {Suspense, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Ping from './pages/Ping';
import ProtectedRoute from './components/ProtectedRoute';
import {CssBaseline} from '@mui/material';
import {useAppDispatch, useAppSelector} from './hooks';
import {testAccessToken} from './features/user/userSlice';
import {Helmet} from 'react-helmet';

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(testAccessToken());
  });

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);
  const isChecked = useAppSelector((state) => state.user?.isChecked);
  const appTitle =
    process.env.REACT_APP_CLOUD_ENV === 'production'
      ? 'EMOS Reports | Enspyre Inc.'
      : 'EMOS Reports - Staging | Enspyre Inc.';
  return (
    <Suspense fallback="loading">
      <Helmet>
        <title>{appTitle}</title>
      </Helmet>
      <CssBaseline />
      {isChecked && (
        <Switch>
          {/* <Redirect exact from="/" to="/home" /> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/ping" component={Ping} />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            authenticationPath="/login"
            path="/"
            component={Home}
          />
        </Switch>
      )}
    </Suspense>
  );
}

export default App;
