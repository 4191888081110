import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpService from '../../services/httpService';
import Helpers from '../../util/helpers';

export const getDashboardData = createAsyncThunk('dashboard/getDashboardData', async (_, thunkAPI: any) => {
  const state = thunkAPI.getState();
  try {
    const response = await httpService.get('/dashboard/', {
      params: {
        date: Helpers.formatDate(state.dashboard.selectedDate),
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('No Dashboard data');
  }
});

export const getCaseSuccessCount = createAsyncThunk('dashboard/getCaseSuccessCount', async (_, thunkAPI: any) => {
  try {
    const response = await httpService.get('/dashboard/case-success-count');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('No Dashboard data');
  }
});

export const getDashboardDataByCase = createAsyncThunk('dashboard/getDashboardDataByCase', async (_, thunkAPI: any) => {
  const state = thunkAPI.getState();
  if (!state.dashboard.selectedCase) return;
  try {
    const response = await httpService.get('/dashboard/by-case', {
      params: {
        case_id: state.dashboard.selectedCase.id,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('No Dashboard data');
  }
});

export const getCompaniesCases = createAsyncThunk('dashboard/getCompaniesCases', async (_, thunkAPI: any) => {
  const state = thunkAPI.getState();
  if (!state.dashboard.selectedCase) return;
  try {
    const response = await httpService.get('/dashboard/companies-cases', {
      params: {
        case_id: state.dashboard.selectedCase.id,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('No Dashboard data');
  }
});

export const getEmployees = createAsyncThunk('dashboard/getEmployees', async (_, thunkAPI) => {
  try {
    const response = await httpService.get('/dashboard/employees');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('No Employees');
  }
});

export const getOpenCases = createAsyncThunk('dashboard/getOpenCases', async (_, thunkAPI) => {
  try {
    const response = await httpService.get('/dashboard/open-cases');
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('No Open Cases');
  }
});

const initialState = {
  dashboardPath: '',
  dashboardData: [],
  dashboardDataLoading: false,
  dashboardDataByCase: [],
  dashboardDataByCaseLoading: false,
  performanceData: [0, 0],
  goalData: [0, 0],
  openCases: [],
  openCasesLoading: false,
  employees: [],
  employeesLoading: false,
  companiesCases: [],
  companiesCasesLoading: false,
  pms: [],
  successesCount: [],
  caseSuccessCount: [],
  caseSuccessCountLoading: false,
  selectedDate: new Date(Date.now() - 86400000).toString(),
  selectedPm: '',
  selectedCase: '',
  byCaseData: {
    workingHoursFT: 0,
    workingHoursDFT: 0,
    workingHoursPT: 0,
    meetingHoursFT: 0,
    meetingHoursDFT: 0,
    meetingHoursPT: 0,
    cost1: 0,
    cost2: 0,
  },
};

const outboundCostFT = 270;
const outboundCostDFT = 218;
const outboundCostPT = 182;

const meetingCostFT = 270;
const meetingCostDFT = 218;
const meetingCostPT = 182;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedCase: (state, action) => {
      state.selectedCase = action.payload;
    },
    setSelectedPm: (state, action) => {
      state.selectedPm = action.payload;
    },
    setDashboardPath: (state, action) => {
      state.dashboardPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployees.pending, (state, _action) => {
      state.employeesLoading = true;
    });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.employees = action.payload;

      const activePms = action.payload.filter((row: any) => row.position === 'PM' && !row.closed);
      state.pms = activePms;
      state.selectedPm = activePms.length > 0 ? activePms[0] : null;

      state.employeesLoading = false;
      return state;
    });
    builder.addCase(getEmployees.rejected, (state, _action) => {
      state.employeesLoading = false;
      return state;
    });
    builder.addCase(getOpenCases.pending, (state, _action) => {
      state.openCasesLoading = true;
    });
    builder.addCase(getOpenCases.fulfilled, (state, action) => {
      state.openCases = action.payload;
      state.selectedCase = action.payload.length > 0 ? action.payload[0] : null;
      state.openCasesLoading = false;
      return state;
    });
    builder.addCase(getOpenCases.rejected, (state, _action) => {
      state.openCasesLoading = false;
      return state;
    });
    builder.addCase(getDashboardData.pending, (state, _action) => {
      state.dashboardDataLoading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.dashboardDataLoading = false;
      state.dashboardData = action.payload;
      state.performanceData = [
        action.payload.filter((row: any) => row.calling_hours / row.working_hours < 0.5).length,
        action.payload.filter((row: any) => row.calling_hours / row.working_hours >= 0.5).length,
      ];
      state.goalData = [
        action.payload.filter((row: any) => parseFloat(row.daily_goal_percent) < 100).length,
        action.payload.filter((row: any) => parseFloat(row.daily_goal_percent) >= 100).length,
      ];
      return state;
    });
    builder.addCase(getDashboardData.rejected, (state, _action) => {
      state.dashboardDataLoading = false;
      return state;
    });
    builder.addCase(getCaseSuccessCount.pending, (state, _action) => {
      state.caseSuccessCountLoading = true;
    });
    builder.addCase(getCaseSuccessCount.fulfilled, (state, action) => {
      state.caseSuccessCountLoading = false;
      state.caseSuccessCount = action.payload;
      return state;
    });
    builder.addCase(getCaseSuccessCount.rejected, (state, _action) => {
      state.caseSuccessCountLoading = false;
      return state;
    });
    builder.addCase(getDashboardDataByCase.pending, (state, _action) => {
      state.dashboardDataByCaseLoading = true;
    });
    builder.addCase(getDashboardDataByCase.fulfilled, (state, action) => {
      state.dashboardDataByCaseLoading = false;
      let data = action.payload;
      if (action.payload) {
        state.byCaseData.workingHoursFT = action.payload
          .filter((row: any) => row.position === 'FT' || row.position === 'ft')
          .map((row: any) => row.working_hours || 0)
          .reduce((partialSum: any, a: any) => partialSum + a, 0);
        state.byCaseData.workingHoursDFT = action.payload
          .filter((row: any) => row.position === 'DFT' || row.position === 'dft')
          .map((row: any) => row.working_hours || 0)
          .reduce((partialSum: any, a: any) => partialSum + a, 0);
        state.byCaseData.workingHoursPT = action.payload
          .filter((row: any) => row.position === 'PT' || row.position === 'pt')
          .map((row: any) => row.working_hours || 0)
          .reduce((partialSum: any, a: any) => partialSum + a, 0);
        state.byCaseData.meetingHoursFT = action.payload
          .filter((row: any) => row.position === 'FT' || row.position === 'ft')
          .map((row: any) => row.meeting_hours || 0)
          .reduce((partialSum: any, a: any) => partialSum + a, 0);
        state.byCaseData.meetingHoursDFT = action.payload
          .filter((row: any) => row.position === 'DFT' || row.position === 'dft')
          .map((row: any) => row.meeting_hours || 0)
          .reduce((partialSum: any, a: any) => partialSum + a, 0);
        state.byCaseData.meetingHoursPT = action.payload
          .filter((row: any) => row.position === 'PT' || row.position === 'pt')
          .map((row: any) => row.meeting_hours || 0)
          .reduce((partialSum: any, a: any) => partialSum + a, 0);

        state.byCaseData.cost1 =
          state.byCaseData.workingHoursFT * outboundCostFT +
          state.byCaseData.workingHoursDFT * outboundCostDFT +
          state.byCaseData.workingHoursPT * outboundCostPT +
          state.byCaseData.meetingHoursFT * meetingCostFT +
          state.byCaseData.meetingHoursDFT * meetingCostDFT +
          state.byCaseData.meetingHoursPT * meetingCostPT;

        state.dashboardDataByCase = data;
      }
      return state;
    });
    builder.addCase(getDashboardDataByCase.rejected, (state, _action) => {
      state.dashboardDataByCaseLoading = false;
      return state;
    });
    builder.addCase(getCompaniesCases.pending, (state, _action) => {
      state.companiesCasesLoading = true;
    });
    builder.addCase(getCompaniesCases.fulfilled, (state, action) => {
      state.companiesCasesLoading = false;
      state.companiesCases = action.payload;
      return state;
    });
    builder.addCase(getCompaniesCases.rejected, (state, _action) => {
      state.companiesCasesLoading = false;
      return state;
    });
  },
});

export const { setSelectedDate, setSelectedPm, setSelectedCase } = dashboardSlice.actions;

export default dashboardSlice.reducer;
