import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  Theme,
} from '@mui/material';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import {useState, useEffect} from 'react';

import {useAppDispatch, useAppSelector} from '../../../../hooks';
import {changeColumnOrder} from '../../caseSlice';
import {createStyles, makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';

interface Column {
  name: string;
  table_name: string;
  caption: string;
  question_id: number;
  order: number;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    cardContent: {
      padding: 0,
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 0,
      },
    },
  }),
);

const SelectedColumns = (props: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const tableColumns = useAppSelector((state) => state.case.tableColumns);
  const [selectedColumns, setSelectedColumns] = useState<Column[]>([]);
  const {t} = useTranslation();

  const onDragEnd = (result: DropResult) => {
    const {destination, source, draggableId} = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const column = selectedColumns.find(
      (col: Column) => col.name === draggableId,
    );
    if (column) {
      const newColumns = Array.from(selectedColumns);
      newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, column);
      dispatch(
        changeColumnOrder({
          columns: newColumns,
        }),
      );
    }
  };

  useEffect(() => {
    setSelectedColumns(
      tableColumns
        .map((table) => {
          return table.columns
            .filter((col: any) => {
              return col.included;
            })
            .map((col: any) => {
              return {
                name: col.column_name,
                table_name: col.table_name,
                caption: col.caption,
                question_id: col.question_id,
                order: col.order,
              };
            });
        })
        .flat()
        .sort((a: Column, b: Column) => a.order - b.order),
    );
  }, [tableColumns]);

  return (
    <Card {...props}>
      <CardHeader
        title={t('pages.caseReportSettings.selectedColumns')}
        titleTypographyProps={{variant: 'h6'}}
        subheader={t('pages.caseReportSettings.dragAndDrop')}
        subheaderTypographyProps={{variant: 'subtitle2'}}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <List>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="column-droppable">
              {(dropProvided, _snapshot) => (
                <div ref={dropProvided.innerRef}>
                  {selectedColumns.map((col, idx) => (
                    <Draggable
                      key={col.name}
                      draggableId={col.name}
                      index={idx}>
                      {(dragProvided, _snapshot) => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}>
                          <ListItem divider>
                            <ListItemText
                              primary={col.caption || col.name}
                              {...dragProvided.dragHandleProps}
                            />
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
      </CardContent>
    </Card>
  );
};

export default SelectedColumns;
