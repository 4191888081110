import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Button, CircularProgress, Container, Grid, IconButton, Toolbar, Typography, Theme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams, Redirect } from 'react-router';
import { goBack } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from '../hooks';
import CaseReportSettingsColumnsCard from '../features/case/components/CaseReportSettingsColumnsCard';
import SelectedColumns from '../features/case/components/report_settings/SelectedColumns';
import { getCaseData, getReportSettings, saveReportSettings, undoChanges } from '../features/case/caseSlice';
import CaseReportSettingsOtherCard from '../features/case/components/CaseReportSettingsOtherCard';
import httpService from '../services/httpService';
import FlexStretch from '../components/FlexStretch';
import ReportTemplateCreateDialog from '../components/ReportTemplateCreateDialog';
import ReportTemplateSelectDialog from '../components/ReportTemplateSelectDialog';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    toolbar: {
      padding: 0,
      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
    card: {
      marginTop: theme.spacing(4),
    },
    cardContent: {
      padding: 0,
    },
    cardActions: {
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
    nested: {
      paddingLeft: theme.spacing(12),
      paddingTop: 0,
      paddingBottom: 0,
    },
    flexStretch: {
      flex: 1,
    },
    listItemText: {
      width: 400,
      maxWidth: 400,
    },
  }),
);

type Params = {
  id: string,
};

const ReportingCaseSettings = () => {
  const { id } = useParams<Params>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [openCreateTemplate, setOpenCreateTemplate] = React.useState(false);
  const [openSelectTemplate, setOpenSelectTemplate] = React.useState(false);

  const isLoading = useAppSelector((state) => state.case.isLoading);
  const hasCaseSettings = useAppSelector((state) => state.case.hasCaseSettings);
  const tableColumns = useAppSelector((state) => state.case.tableColumns);
  const currentCase = useAppSelector((state) => state.case.currentCase);
  const reportSettingsChangeDetected = useAppSelector((state) => state.case.reportSettingsChangeDetected);
  const isSaving = useAppSelector((state) => state.case.isSaving);

  const handleClickOpenCreateTemplate = () => {
    setOpenCreateTemplate(true);
  };

  const handleCloseCreateTemplate = () => {
    setOpenCreateTemplate(false);
  };

  const handleClickOpenSelectTemplate = () => {
    setOpenSelectTemplate(true);
  };

  const handleCloseSelectTemplate = () => {
    setOpenSelectTemplate(false);
  };

  const initializeCaseSettings = async (id: string) => {
    try {
      await httpService.post('/case-settings', { case_id: id });
      dispatch(getReportSettings(id));
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    dispatch(getCaseData(id));
    dispatch(getReportSettings(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUserRole = useAppSelector((state) => state.user?.role);

  if (currentUserRole !== 'pm') {
    return <Redirect to={{ pathname: `/cases/${id}/report` }} />;
  }

  return (
    <>
      <Container fixed>
        <Toolbar className={classes.toolbar}>
          <IconButton aria-label="back" onClick={() => dispatch(goBack())}>
            <ArrowBackIcon />
          </IconButton>
          {currentCase && (
            <>
              <Typography variant="h5">{currentCase.case_name}</Typography>
              <Button size="small" href={`/cases/${id}/report`} color="primary">
                {t('pages.caseReportSettings.viewReport')}
              </Button>
            </>
          )}
          <FlexStretch />
          {!isLoading && (
            <Button color="primary" onClick={handleClickOpenSelectTemplate} disabled={isSaving}>
              {t('pages.caseReportSettings.useTemplate')}
            </Button>
          )}
          {openSelectTemplate && (
            <ReportTemplateSelectDialog caseId={id} open={openSelectTemplate} onClose={handleCloseSelectTemplate} />
          )}
          {!isLoading && (
            <Button variant="contained" color="primary" onClick={handleClickOpenCreateTemplate} disabled={isSaving}>
              {t('pages.caseReportSettings.saveAsTemplate')}
            </Button>
          )}

          {openCreateTemplate && !isLoading && (
            <ReportTemplateCreateDialog
              caseId={id}
              open={openCreateTemplate}
              onClose={handleCloseCreateTemplate}
              tableColumns={tableColumns}
            />
          )}
        </Toolbar>
        {isLoading && <CircularProgress />}
        {!isLoading && hasCaseSettings && (
          <>
            <Grid container spacing={3}>
              <Grid item sm={12} md={8}>
                <CaseReportSettingsColumnsCard />
              </Grid>
              <Grid item sm={12} md={4}>
                <SelectedColumns />
              </Grid>
            </Grid>
            <CaseReportSettingsOtherCard />
            <Toolbar className={classes.toolbar}>
              <FlexStretch />
              <Button
                disabled={!reportSettingsChangeDetected || isSaving}
                color="primary"
                onClick={() => dispatch(undoChanges())}>
                {t('pages.caseReportSettings.undo')}
              </Button>
              <Button
                disabled={!reportSettingsChangeDetected || isSaving}
                color="primary"
                variant="contained"
                onClick={() => dispatch(saveReportSettings(id))}>
                {t('pages.caseReportSettings.save')}
              </Button>
            </Toolbar>
          </>
        )}
        {!isLoading && !hasCaseSettings && (
          <div>
            No case settings
            <Button onClick={() => initializeCaseSettings(id)}>Initialize Case Settings</Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default ReportingCaseSettings;
