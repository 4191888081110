import {History} from 'history';
import {connectRouter} from 'connected-react-router';
import userSlice from '../features/user/userSlice';
import caseSlice from '../features/case/caseSlice';
import dashboardSlice from '../features/dashboard/dashboardSlice';

const rootReducer = (history: History) => ({
  router: connectRouter(history),
  user: userSlice,
  case: caseSlice,
  dashboard: dashboardSlice,
});

export default rootReducer;
