import { push } from 'connected-react-router';

// Hooks
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../hooks';

// MUI components
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { Box, IconButton, Paper, TableContainer, TextField } from '@mui/material';

// MUI icons
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import SettingsIcon from '@mui/icons-material/Settings';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

// Services
import httpService from '../services/httpService';

interface Props {}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onSubmit: () => void;
  onChange: (newVal: string) => void;
  value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const { t } = useTranslation();
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      props.onSubmit();
    } else {
      return true;
    }
  };
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}>
      <TextField
        variant="standard"
        value={props.value}
        onKeyDown={handleKeyDown}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={t('common.search')}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: 'auto',
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          '& .MuiSvgIcon-root': {
            mr: 0.5,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        }}
      />
    </Box>
  );
}

const CaseList = (_props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchSubmitText, setSearchSubmitText] = useState('');
  const [rows, setRows] = useState([]);
  const [pageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ]);
  const currentUserRole = useAppSelector((state) => state.user?.role);

  const [columns] = useState<GridColDef[]>([
    {
      field: 'actions',
      headerName: t('pages.caseList.actions'),
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(push('/cases/' + params.id + '/report'));
            }}>
            <OpenInBrowserIcon />
          </IconButton>
          {currentUserRole === 'pm' && (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(push('/cases/' + params.id + '/report/settings'));
              }}>
              <SettingsIcon />
            </IconButton>
          )}
        </>
      ),
      width: 90,
    },
    {
      field: 'id',
      headerName: t('pages.caseList.id'),
      width: 90,
    },
    {
      field: 'case_name',
      headerName: t('pages.caseList.caseName'),
      width: 400,
    },
    {
      field: 'case_type',
      headerName: t('pages.caseList.caseType'),
      width: 150,
      // sortable: false,
    },
    {
      field: 'status',
      headerName: t('pages.caseList.status'),
      width: 150,
    },
    {
      field: 'start_date',
      headerName: t('pages.caseList.startDate'),
      type: 'date',
      width: 160,
    },
    {
      field: 'end_date',
      headerName: t('pages.caseList.endDate'),
      type: 'date',
      width: 160,
    },
    {
      field: 'close_date',
      headerName: t('pages.caseList.closeDate'),
      type: 'date',
      width: 160,
    },
  ]);

  const fetchCases = async () => {
    setLoading(true);
    let params: any = {};
    if (searchSubmitText !== '') {
      params['name'] = `%${searchSubmitText}%`;
    }
    const response = await httpService.get('/cases/', { params });
    setRows(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSubmitText]);

  return (
    <>
      <QuickSearchToolbar
        value={searchText}
        onSubmit={() => {
          setSearchSubmitText(searchText);
        }}
        onChange={(newVal) => setSearchText(newVal)}
        clearSearch={() => {
          setSearchText('');
          setSearchSubmitText('');
        }}
      />
      <TableContainer component={Paper}>
        <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            localeText={{
              columnMenuFilter: t('dataGrid.columnMenuFilter'),
              columnMenuHideColumn: t('dataGrid.columnMenuHideColumn'),
              columnMenuShowColumns: t('dataGrid.columnMenuShowColumns'),
              columnMenuSortAsc: t('dataGrid.columnMenuSortAsc'),
              columnMenuSortDesc: t('dataGrid.columnMenuSortDesc'),
              columnMenuUnsort: t('dataGrid.columnMenuUnsort'),
              columnsPanelHideAllButton: t('dataGrid.columnsPanelHideAllButton'),
              columnsPanelShowAllButton: t('dataGrid.columnsPanelShowAllButton'),
              columnsPanelTextFieldLabel: t('dataGrid.columnsPanelTextFieldLabel'),
              columnsPanelTextFieldPlaceholder: t('dataGrid.columnsPanelTextFieldPlaceholder'),
              filterOperatorContains: t('dataGrid.filterOperatorContains'),
              filterOperatorEndsWith: t('dataGrid.filterOperatorEndsWith'),
              filterOperatorEquals: t('dataGrid.filterOperatorEquals'),
              filterOperatorIs: t('dataGrid.filterOperatorIs'),
              filterOperatorIsEmpty: t('dataGrid.filterOperatorIsEmpty'),
              filterOperatorIsNotEmpty: t('dataGrid.filterOperatorIsNotEmpty'),
              filterOperatorStartsWith: t('dataGrid.filterOperatorStartsWith'),
              filterPanelColumns: t('dataGrid.filterPanelColumns'),
              filterPanelInputLabel: t('dataGrid.filterPanelInputLabel'),
              filterPanelInputPlaceholder: t('dataGrid.filterPanelInputPlaceholder'),
              filterPanelOperators: t('dataGrid.filterPanelOperators'),
              noResultsOverlayLabel: t('dataGrid.noResultsOverlayLabel'),
            }}
            // paginationMode="server"
            loading={loading}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            disableSelectionOnClick
            // disableColumnFilter
            // disableColumnMenu
            density="compact"
            onRowClick={(e) => dispatch(push('/cases/' + e.id + '/report'))}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </div>
      </TableContainer>
    </>
  );
};

export default CaseList;
