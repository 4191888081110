import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpService from '../../services/httpService';

const orderSort = (a: any, b: any) => {
  return a.order - b.order;
};

const mapColumnsToSettings = (columns: any, settings: any) => {
  return columns
    .map((tc: any, index: number) => {
      const tableSettings = settings &&
        settings.table_columns &&
        settings.table_columns.length > 0 &&
        settings.table_columns.find(
          (tab: any) => tab.table_name === tc.table_name,
        );
      tc.open = false;
      tc.order = tableSettings?.order === 0 ? 0 : (tableSettings?.order || index);
      
      tc.columns = tc.columns
        .map((c: any, index: number) => {
          const matchedSetting = 
              (tc.table_name !== 'questions' && tableSettings?.columns
                .find((col: any) => col.column_name === c.column_name)) ||
               (tc.table_name === 'questions' && tableSettings?.columns
                 .find((col: any) => col.question_id === c.question_id));
          c.included = !!(matchedSetting && matchedSetting.included);
          c.editing = false;
          c.caption = matchedSetting?.caption || '';
          c.order = matchedSetting?.order === 0 ? 0 : (matchedSetting?.order || index);
          return c;
        })
      return tc;
      
    })
    .sort(orderSort);
};

const checkIfAllIncluded = (table: any) => {
  if(table.columns.length === 0)
    return false;
  let lengthOfIncludedColumns = table.columns.filter(
    (c: any) => c.included,
  ).length;
  return lengthOfIncludedColumns === table.columns.length;
};

export const getCaseData = createAsyncThunk(
  'case/getCaseData',
  async (id: string, thunkAPI) => {
    try {
      const response = await httpService.get(
        '/cases/' + id,
      );
      return { currentCase: response.data, currentCaseId: response.data?.id }
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.detail === 'Case data not found'
      )
        return thunkAPI.rejectWithValue("Case data not found");
    }


  }
)

export const getReportSettings = createAsyncThunk(
  'case/getReportSettings',
  async (id: string, thunkAPI) => {
    try {
      const reportSettingsResult = await httpService.get(
        '/cases/' + id + '/case-settings/report',
      );
      const allColumns = await httpService.get('/cases/' + id + '/all-columns');
      let tableColumns = mapColumnsToSettings(
        allColumns.data,
        reportSettingsResult.data,
      );
      return { currentCaseId: id, reportSettings: reportSettingsResult.data, tableColumns };
    } catch (error: any) {
      if (
        error.response.status === 404 &&
        error.response.data.detail === 'Case Setting not found'
      )
        return thunkAPI.rejectWithValue("Case Setting not found");
    }
  },
);

export const saveReportSettings = createAsyncThunk(
  'case/saveReportSettings',
  async (id: string, thunkAPI) => {
    const state = thunkAPI.getState() as any;
    try {
      const newReportSettings = {
        ...state.case.reportSettings,
        table_columns: state.case.tableColumns,
        show_only_last_call: state.case.showOnlyLastCall || false,
      };
      const reportSettings = await httpService.put(
        `/cases/${id}/case-settings/report`,
        newReportSettings,
      );
      return { reportSettings: reportSettings.data };
    } catch (error: any) {
      console.log(error);
    }
  },
);

export const initializeCaseSettings = createAsyncThunk(
  'case/initializeCaseSettings',
  async (id: string, thunkAPI) => {
    try {
      await httpService.post('/case-settings', {case_id: id});
      await getReportSettings(id);
    } catch (error: any) {
      console.log(error.response.data);
    }
  },
);

export const useReportTemplate = createAsyncThunk(
  'case/useReportTemplate',
  async ({id, reportTemplateSettings} : {id: string, reportTemplateSettings: any}, thunkAPI) => {
    // const state = thunkAPI.getState() as any;
    try {
      const allColumns = await httpService.get('/cases/' + id + '/all-columns');
      let tableColumns = mapColumnsToSettings(
        allColumns.data,
        reportTemplateSettings,
      );
      const newReportSettings = {
        table_columns: reportTemplateSettings.table_columns,
        show_only_last_call: reportTemplateSettings.showOnlyLastCall,
      };
      const reportSettings = await httpService.put(
        `/cases/${id}/case-settings/report`,
        newReportSettings,
      );
      return { reportSettings: reportSettings.data, tableColumns };
    } catch (error: any) {
      console.log(error);
      // if (
      //   error.response.status === 404 &&
      //   error.response.data.detail === 'Case Setting not found'
      // )
        return thunkAPI.rejectWithValue("Case Setting not found");
    }
  },
);

const initialState = {
  currentCaseId: null,
  currentCase: {} as any,
  reportSettings: {} as any,
  tableColumns: [] as any[],
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  hasCaseSettings: true,
  isSaving: false,
  reportSettingsChangeDetected:false,
  columnsChangeDetected: false,
  columnsSaving: false,
  isTemplate: false,
  templateName: '',
  showOnlyLastCall: false,
  othersChangeDetected: false,
  othersSaving: false
};

const caseSlice = createSlice({
  name: 'case',
  initialState,
  reducers: {
    expandTable: (state, action) => {
      state.tableColumns = state.tableColumns.map((e: any) => {
        if (e.table_name === action.payload.table_name) {
          e.open = !e.open;
        }
        return e;
      });
    },
    includeAllColumnsInTable: (state, action) => {
      state.reportSettingsChangeDetected = true
      let newTableColumns = [];
      if (checkIfAllIncluded(action.payload)) {
        newTableColumns = state.tableColumns.map((tc: any) => {
          if (tc.table_name === action.payload.table_name) {
            tc.columns = tc.columns.map((c: any) => {
              c.included = false;
              return c;
            });
          }
          return tc;
        });
      } else {
        let newOrder = 0;
        state.tableColumns.forEach((t: any) => {
          newOrder += t.columns.filter((c: any) => c.included).length;
        });
        newTableColumns = state.tableColumns.map((tc: any) => {
          if (tc.table_name === action.payload.table_name) {
            tc.columns = tc.columns.map((c: any) => {
              if (!c.included) {
                c.order = newOrder;
                newOrder++;
              }
              c.included = true;
              return c;
            });
          }
          return tc;
        });
      }
      state.tableColumns = newTableColumns
    },
    includeSingleColumnInTable: (state, action) => {
      state.reportSettingsChangeDetected = true
      let newOrder = 0;
      state.tableColumns.forEach((t: any) => {
        newOrder += t.columns.filter((c: any) => c.included).length;
      });
      let newTableColumns = state.tableColumns.map((tc: any) => {
        if (tc.table_name === action.payload.table.table_name) {
          let index = tc.columns.findIndex(
            (x: any) => x.column_name === action.payload.column.column_name,
          );
          if (action.payload.table.table_name === 'questions')
            index = tc.columns.findIndex(
              (x: any) => x.question_id === action.payload.column.question_id,
            );
          if (!tc.columns[index].included) {
            tc.columns[index].order = newOrder;
          }
          tc.columns[index].included = !tc.columns[index].included;
        }
        return tc;
      });
      state.tableColumns = newTableColumns
    },
    undoChanges: (state) => {
      state.reportSettingsChangeDetected = false;
      state.tableColumns = mapColumnsToSettings(state.tableColumns, state.reportSettings)
      state.showOnlyLastCall = state.reportSettings.show_only_last_call
    },
    moveTable: (state, action) => {
      state.reportSettingsChangeDetected = true;
      const newTableColumns = [...state.tableColumns];
      const [removed] = newTableColumns.splice(action.payload.startIndex, 1);
      newTableColumns.splice(action.payload.endIndex, 0, removed);
      state.tableColumns = newTableColumns.map(
        (tc: any, index: number) => {
          tc.order = index;
          return tc;
        },
      );
    },
    moveColumn: (state, action) => {
      state.reportSettingsChangeDetected = true;
      let newTableColumns = [...state.tableColumns].map((table) => {
        if(table.table_name === action.payload.tableName) {
          const newColumns = [...table.columns];
          const [removed] = newColumns.splice(action.payload.startIndex, 1);
          newColumns.splice(action.payload.endIndex, 0, removed);
          table.columns = newColumns.map(
            (column: any, index: number) => {
              column.order = index;
              return column;
            },
          )
        }
        return table
      });
      state.tableColumns = newTableColumns
    },
    changeColumnOrder: (state, action) => {
      state.reportSettingsChangeDetected = true
      let newTableColumns = state.tableColumns.map((tc: any) => {
        action.payload.columns.forEach((col: any, idx: number) => {
          let index = tc.columns.findIndex(
            (x: any) => x.column_name === col.name,
          );
          if (col.table_name === 'questions' && tc.table_name === 'questions') {
            index = tc.columns.findIndex(
              (x: any) => x.question_id === col.question_id,
            );
          }
          if (index >= 0) {
            tc.columns[index].order = idx;
          }
        })
        return tc;
      });
      state.tableColumns = newTableColumns
    },
    changeCaption: (state, action) => {
      state.reportSettingsChangeDetected = true;
      state.tableColumns = state.tableColumns.map((table, i) => i === action.payload.tableIndex ? { ...table, columns: table.columns.map((column: any, j: any) => j === action.payload.columnIndex ? { ...column, caption: action.payload.value } : column) } : table)
    },
    changeShowOnlyLastCall: (state) => {
      state.reportSettingsChangeDetected = true;
      state.showOnlyLastCall = !state.showOnlyLastCall;
    }
  },
  extraReducers: (builder) => {
    // getCaseData
    builder.addCase(getCaseData.pending, (state, _action) => {
      state.isLoading = false;
    });
    builder.addCase(getCaseData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentCase = action.payload?.currentCase;
      state.currentCaseId = action.payload?.currentCaseId;
    });
    builder.addCase(getCaseData.rejected, (state, _action) => {
      state.isLoading = false;
    });
    // getReportSettings
    builder.addCase(getReportSettings.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReportSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasCaseSettings = true;
      state.isTemplate = action.payload?.reportSettings?.is_template;
      state.templateName = action.payload?.reportSettings?.template_name;
      state.showOnlyLastCall = action.payload?.reportSettings?.show_only_last_call;
      state.tableColumns = action.payload?.tableColumns;
      state.reportSettings = action.payload?.reportSettings;
    });
    builder.addCase(getReportSettings.rejected, (state, action) => {
      if(action.payload === "Case Setting not found")
        state.hasCaseSettings = false;
      state.isLoading = false;
    });
    // saveReportSettings
    builder.addCase(saveReportSettings.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(saveReportSettings.fulfilled, (state, action) => {
      state.isSaving = false;
      state.reportSettingsChangeDetected = false;
      state.reportSettings = action.payload?.reportSettings;
    });
    builder.addCase(saveReportSettings.rejected, (state, action) => {
      state.isSaving = false;
    });
    // initializeCaseSettings
    builder.addCase(initializeCaseSettings.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(initializeCaseSettings.fulfilled, (state, action) => {
      state.isSaving = false;
    });
    builder.addCase(initializeCaseSettings.rejected, (state, action) => {
      state.isSaving = false;
    });
    // useReportTemplate
    builder.addCase(useReportTemplate.pending, (state, action) => {
      state.isSaving = true;
    });
    builder.addCase(useReportTemplate.fulfilled, (state, action) => {
      state.isSaving = false;
      state.reportSettingsChangeDetected = false;
      state.reportSettings = action.payload?.reportSettings;
      state.tableColumns = action.payload?.tableColumns;
    });
    builder.addCase(useReportTemplate.rejected, (state, action) => {
      state.isSaving = false;
    });
  },
});

export const {
  expandTable,
  includeAllColumnsInTable,
  includeSingleColumnInTable,
  undoChanges,
  moveTable,
  moveColumn,
  changeCaption,
  changeColumnOrder,
  changeShowOnlyLastCall,
} = caseSlice.actions;

export default caseSlice.reducer;
