import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';
import logger from 'redux-logger';
import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development')
      return getDefaultMiddleware()
        .concat(routerMiddleware(history))
        .concat(logger);
    return getDefaultMiddleware().concat(routerMiddleware(history));
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
