import { CircularProgress, Grid, Paper, TextField, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import DatePicker from '@mui/lab/DatePicker';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  getDashboardData,
  getCaseSuccessCount,
  setSelectedCase,
  setSelectedDate,
  setSelectedPm,
} from '../features/dashboard/dashboardSlice';
import NoDashboardData from '../features/dashboard/components/NoDashboardData';
import DashboardDataLoading from '../features/dashboard/components/DashboardDataLoading';
import { useHistory } from 'react-router';

Chart.register(ArcElement, Tooltip, Legend, Title);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: '0 !important',
      '& > *': {
        marginRight: `${theme.spacing(2)} !important`,
      },
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      textAlign: 'center',
      color: '#34a853',
      fontWeight: 'bold',
    },
    table: {
      fontSize: '0.875rem',
      width: '100%',
      marginBottom: theme.spacing(2),
      border: '1px solid lightgrey',
      borderCollapse: 'collapse',
      '& th, td': {
        border: '1px solid lightgrey',
        padding: '1px 4px',
        wordWrap: 'break-word',
        maxWidth: 120,
      },
      '& thead': {
        color: '#fff',
        textAlign: 'left',
      },
    },
    redTable: {
      '& thead': {
        backgroundColor: '#ff0000',
      },
      '& tbody': {
        backgroundColor: '#fce5cd',
      },
    },
    blueTable: {
      '& thead': {
        backgroundColor: '#4a86e8',
      },
      '& tbody': {
        backgroundColor: '#d0e0e3',
      },
    },
    greenTable: {
      '& thead': {
        backgroundColor: '#34a853',
      },
    },
    chart: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  }),
);

const DashboardMain = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dashboardData: any[] = useAppSelector((state) => state.dashboard.dashboardData);
  const dashboardDataLoading: boolean = useAppSelector((state) => state.dashboard.dashboardDataLoading);
  const selectedDate: string = useAppSelector((state) => state.dashboard.selectedDate);
  const employees: any[] = useAppSelector((state) => state.dashboard.employees);
  const employeesLoading: boolean = useAppSelector((state) => state.dashboard.employeesLoading);
  const openCases: any[] = useAppSelector((state) => state.dashboard.openCases);
  const openCasesLoading: boolean = useAppSelector((state) => state.dashboard.openCasesLoading);
  const performanceData: number[] = useAppSelector((state) => state.dashboard.performanceData);
  const goalData: number[] = useAppSelector((state) => state.dashboard.goalData);
  const caseSuccessCount: any[] = useAppSelector((state) => state.dashboard.caseSuccessCount);
  const caseSuccessCountLoading: boolean = useAppSelector((state) => state.dashboard.caseSuccessCountLoading);

  const isLoading = dashboardDataLoading || employeesLoading || openCasesLoading || caseSuccessCountLoading;

  const history = useHistory();

  const handleChangeSelectedDate = (date: Date | null) => {
    if (date) {
      dispatch(setSelectedDate(date.toString()));
      dispatch(getDashboardData());
      dispatch(getCaseSuccessCount());
    }
  };

  const goToPmView = (pm: any) => {
    dispatch(setSelectedPm(pm));
    history.push('/dashboard/by-pm');
  };

  const goToCaseView = (caseId: number) => {
    let openCase = openCases.find((openCase) => openCase.id === caseId);
    if (openCase) {
      dispatch(setSelectedCase(openCase));
      history.push('/dashboard/by-case');
    }
  };

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <DatePicker
          mask="____-__-__"
          label={t('pages.dashboard.date').toString()}
          value={selectedDate}
          inputFormat="yyyy-MM-dd"
          onChange={handleChangeSelectedDate}
          disabled={isLoading}
          renderInput={(params: any) => <TextField variant="standard" {...params} />}
        />
        {isLoading && <CircularProgress />}
      </Toolbar>
      {dashboardData.length > 0 && !isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.chart}>
              <Doughnut
                data={{
                  labels: ['未達標', '已達標'],
                  datasets: [
                    {
                      data: performanceData,
                      backgroundColor: ['#ff0000', '#4a86e8'],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: '線上工時',
                    },
                    tooltip: {
                      enabled: false,
                    },
                    datalabels: {
                      color: 'white',
                      formatter: (value, context) => {
                        const datapoints = context.chart.data.datasets[0].data;
                        function totalSum(total: any, datapoint: any) {
                          return total + datapoint;
                        }
                        const totalValue = datapoints.reduce(totalSum, 0);
                        const percentageValue = ((value / totalValue) * 100).toFixed(1);

                        return `${percentageValue}%`;
                      },
                    },
                  },
                }}
                plugins={[ChartDataLabels]}
              />
            </Paper>
            {dashboardData
              .filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0)
              .filter((row) => row.calling_hours / row.working_hours < 0.5).length > 0 && (
              <table className={`${classes.redTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={2}>未達標</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => row.calling_hours && row.working_hours)
                    .filter((row) => row.calling_hours / row.working_hours < 0.5)
                    .map((row) => (
                      <tr key={row.telemarketer_id + row.case_id}>
                        <td>{row.telemarketer_name}</td>
                        <td align="right">{((row.calling_hours / row.working_hours) * 100).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter((row) => row.calling_hours / row.working_hours < 0.5).length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => !row.working_hours).length > 0 && (
              <table className={`${classes.redTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={2}>無外撥時數</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => !row.working_hours || row.working_hours === 0)
                    .map((row) => (
                      <tr key={row.telemarketer_id + row.case_id}>
                        <td>{row.telemarketer_name}</td>
                        <td align="right" onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                      </tr>
                    ))}
                  {dashboardData.filter((row) => !row.working_hours).length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0).length >
              0 && (
              <table className={`${classes.blueTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={2}>{t('pages.dashboard.topThree')}</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0)
                    .sort((a, b) => b.calling_hours / b.working_hours - a.calling_hours / a.working_hours)
                    .slice(0, 3)
                    .map((row) => (
                      <tr key={row.telemarketer_id + row.case_id}>
                        <td>{row.telemarketer_name}</td>
                        <td align="right">{((row.calling_hours / row.working_hours) * 100).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0)
                    .length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0).length >
              0 && (
              <table className={`${classes.redTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={2}>{t('pages.dashboard.bottomThree')}</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0)
                    .sort((a, b) => a.calling_hours / a.working_hours - b.calling_hours / b.working_hours)
                    .slice(0, 3)
                    .map((row) => (
                      <tr key={row.telemarketer_id + row.case_id}>
                        <td>{row.telemarketer_name}</td>
                        <td align="right">{((row.calling_hours / row.working_hours) * 100).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter((row) => row.calling_hours && row.working_hours && row.working_hours !== 0)
                    .length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Paper className={classes.chart}>
              <Doughnut
                data={{
                  labels: ['未達標', '已達標'],
                  datasets: [
                    {
                      data: goalData,
                      backgroundColor: ['#ff0000', '#4a86e8'],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: 'TM達標率',
                    },
                    tooltip: {
                      enabled: false,
                    },
                    datalabels: {
                      color: 'white',
                      formatter: (value, context) => {
                        const datapoints = context.chart.data.datasets[0].data;
                        function totalSum(total: any, datapoint: any) {
                          return total + datapoint;
                        }
                        const totalValue = datapoints.reduce(totalSum, 0);
                        const percentageValue = ((value / totalValue) * 100).toFixed(1);

                        return `${percentageValue}%`;
                      },
                    },
                  },
                }}
                plugins={[ChartDataLabels]}
              />
            </Paper>
            {dashboardData.filter((row) => parseFloat(row.daily_goal_percent) < 100).length > 0 && (
              <table className={`${classes.redTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={3}>未達標</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => parseFloat(row.daily_goal_percent) < 100)
                    .map((row) => (
                      <tr key={row.telemarketer_id}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                        <td align="right">{parseFloat(row.daily_goal_percent || 0).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter((row) => parseFloat(row.daily_goal_percent) < 100).length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => row.daily_goal_percent === '尚未設定單價').length > 0 && (
              <table className={`${classes.redTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={2}>尚未設定單價</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => row.daily_goal_percent === '尚未設定單價')
                    .map((row) => (
                      <tr key={row.telemarketer_id}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                      </tr>
                    ))}
                  {dashboardData.filter((row) => row.daily_goal_percent === '尚未設定單價').length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => row.daily_goal_percent).length > 0 && (
              <table className={`${classes.blueTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={3}>{t('pages.dashboard.topThree')}</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => row.daily_goal_percent)
                    .sort((a, b) => parseFloat(b.daily_goal_percent) - parseFloat(a.daily_goal_percent))
                    .slice(0, 3)
                    .map((row) => (
                      <tr key={row.telemarketer_id}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                        <td align="right">{parseFloat(row.daily_goal_percent || 0).toFixed(1)}%</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => row.daily_goal_percent).length > 0 && (
              <table className={`${classes.redTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={3}>{t('pages.dashboard.bottomThree')}</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => row.daily_goal_percent)
                    .sort((a, b) => parseFloat(a.daily_goal_percent) - parseFloat(b.daily_goal_percent))
                    .slice(0, 3)
                    .map((row) => (
                      <tr key={row.telemarketer_id}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                        <td align="right">{parseFloat(row.daily_goal_percent || 0).toFixed(1)}%</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography gutterBottom component="div" className={classes.title}>
              專案完成數
            </Typography>
            {employees
              .filter((row) => row.position === 'PM' && !row.closed)
              .filter((row) => openCases.filter((openCase) => openCase.pm_id === row.id).length > 0)
              .map((pm) => (
                <table key={pm.id} className={`${classes.greenTable} ${classes.table}`}>
                  <thead>
                    <tr>
                      <th colSpan={3} onClick={() => goToPmView(pm)} style={{ cursor: 'pointer' }}>
                        PM: {pm.english_name || 'No Name'}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {openCases
                      .filter((openCase) => openCase.pm_id === pm.id)
                      .map((openCase) => (
                        <tr key={openCase.id} onClick={() => goToCaseView(openCase.id)} style={{ cursor: 'pointer' }}>
                          <td>{openCase.case_name + ' ' + openCase.id}</td>
                          <td align="right">
                            {/* {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => parseInt(row.successes_cumul || 0))
                              .reduce((partialSum, a) => partialSum + a, 0)} */}
                            {/* <SuccessesCount caseId={openCase.id} /> */}
                            {caseSuccessCount.find((row) => row.case_id === openCase.id)?.success_count || 0}
                          </td>
                          <td align="right">
                            {(
                              !openCase.primary_goal
                              ? 0
                              : Math.min(((caseSuccessCount.find((row) => row.case_id === openCase.id)?.success_count || 0) / openCase.primary_goal) * 100, 100)
                            ).toFixed(1)}%
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ))}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography gutterBottom component="div" className={classes.title}>
              TM達標率
            </Typography>
            {dashboardData.filter((row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'FT')
              .length > 0 && (
              <table className={`${classes.greenTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={3}>FT</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'FT')
                    .sort((a, b) => parseFloat(b.daily_goal_percent || 0) - parseFloat(a.daily_goal_percent || 0))
                    .map((row) => (
                      <tr key={row.telemarketer_name + row.case_name}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                        <td align="right">{parseFloat(row.daily_goal_percent || 0).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter(
                    (row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'FT',
                  ).length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
            {dashboardData.filter((row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'D-FT')
              .length > 0 && (
              <table className={`${classes.greenTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={3}>D-FT</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'D-FT')
                    .sort((a, b) => parseFloat(b.daily_goal_percent || 0) - parseFloat(a.daily_goal_percent || 0))
                    .map((row) => (
                      <tr key={row.telemarketer_name + row.case_name}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                        <td align="right">{parseFloat(row.daily_goal_percent || 0).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter(
                    (row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'D-FT',
                  ).length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}

            {dashboardData.filter((row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'PT')
              .length > 0 && (
              <table className={`${classes.greenTable} ${classes.table}`}>
                <thead>
                  <tr>
                    <th colSpan={3}>PT</th>
                  </tr>
                </thead>
                <tbody>
                  {dashboardData
                    .filter((row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'PT')
                    .sort((a, b) => parseFloat(b.daily_goal_percent || 0) - parseFloat(a.daily_goal_percent || 0))
                    .map((row) => (
                      <tr key={row.telemarketer_name + row.case_id}>
                        <td>{row.telemarketer_name}</td>
                        <td onClick={() => goToCaseView(row.case_id)} style={{ cursor: 'pointer' }}>
                          {row.case_name}
                        </td>
                        <td align="right">{parseFloat(row.daily_goal_percent || 0).toFixed(1)}%</td>
                      </tr>
                    ))}
                  {dashboardData.filter(
                    (row) => employees.find((emp) => emp.id === row.telemarketer_id).position === 'PT',
                  ).length === 0 && (
                    <tr>
                      <td>{t('pages.dashboard.noData')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </Grid>
        </Grid>
      )}
      {isLoading && <DashboardDataLoading />}
      {dashboardData.length === 0 && !isLoading && <NoDashboardData />}
    </>
  );
};

export default DashboardMain;
