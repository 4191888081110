import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const DashboardDataLoading = () => {
  const { t } = useTranslation();
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item padding={2}>
        {t('pages.dashboard.loading')}
      </Grid>
    </Grid>
  );
};

export default DashboardDataLoading;
