import {makeStyles} from '@mui/styles';

interface Props {}

const useStyles = makeStyles({
  flexStretch: {
    flex: 1,
  },
});

const FlexStretch = (props: Props) => {
  const classes = useStyles();

  return <div className={classes.flexStretch}></div>;
};

export default FlexStretch;
