import axios from "axios";

let baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:8888";
}

declare module "axios" {
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

const httpService = axios.create({
  baseURL: baseURL+"/api/v1/",
});

// Add a request interceptor
httpService.interceptors.request.use(function (config: any) {
  const localToken = localStorage.getItem("token");
  if (localToken && config) {
    const token = "Bearer " + localStorage.getItem("token");
    config.headers.Authorization = token;
  }
  return config;
});

export default httpService;
