import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import httpService from '../services/httpService';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  caseId: any;
  tableColumns: any;
}

const ReportTemplateCreateDialog = (props: SimpleDialogProps) => {
  const [templateName, setTemplateName] = useState('');

  const {onClose, open, caseId, tableColumns} = props;

  const {t} = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleSave = async () => {
    let requestBody = {
      template_name: templateName,
      case_id: caseId,
      table_columns: tableColumns,
    };
    await httpService.post('/report-templates', requestBody);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="create-report-template-title" open={open}>
      <DialogTitle id="create-report-template-title">{t('dialog.reportTemplateCreate.saveAsTemplate')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialog.reportTemplateCreate.createTemplateDescription')}</DialogContentText>
        <TextField
          autoComplete="off"
          autoFocus
          margin="dense"
          id="name"
          label={t('dialog.reportTemplateCreate.reportTemplateName')}
          type="text"
          fullWidth
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('dialog.reportTemplateCreate.cancel')}
        </Button>
        <Button disabled={!templateName} onClick={handleSave} color="primary">
          {t('dialog.reportTemplateCreate.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportTemplateCreateDialog;
