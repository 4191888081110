import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import BackgroundImage from '../assets/images/login-bg1.jpg';
import {useForm} from 'react-hook-form';
import {useAppDispatch, useAppSelector} from '../hooks';
import {getAccessToken} from '../features/user/userSlice';
import {Redirect} from 'react-router';
import {Theme} from '@mui/material';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {register, handleSubmit} = useForm();
  const {t} = useTranslation();

  const onSubmit = (data: any) => {
    dispatch(getAccessToken(data));
    // dispatch(push('/'));
  };

  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  // useEffect(() => {
  //   // if isUserLoggedIn turned to true redirect to /home
  //   if (isAuthenticated) {
  //     dispatch(push('/'));
  //   }
  // }, [isAuthenticated]);

  if (isAuthenticated) return <Redirect to="/" />;
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('pages.login.signInWith')}
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('pages.login.email')}
              autoComplete="email"
              autoFocus
              {...register('email')}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={t('pages.login.password')}
              type="password"
              id="password"
              autoComplete="current-password"
              {...register('password')}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}>
              {t('pages.login.signIn')}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
