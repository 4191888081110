import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@mui/material';
import {makeStyles, createStyles} from '@mui/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {
  changeCaption,
  expandTable,
  includeAllColumnsInTable,
  includeSingleColumnInTable,
} from '../caseSlice';
import {useTranslation} from 'react-i18next';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    card: {
      marginTop: theme.spacing(4),
    },
    cardContent: {
      padding: 0,
      '& .MuiCardContent-root:last-child': {
        paddingBottom: 0,
      },
    },
    cardActions: {
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
    nested: {
      paddingLeft: theme.spacing(12),
      paddingTop: 0,
      paddingBottom: 0,
    },
    flexStretch: {
      flex: 1,
    },
    listItemText: {
      width: 320,
      maxWidth: 320,
    },
    dragIconContainer: {
      display: 'flex',
    },
  }),
);

const CaseReportSettingsColumnsCard = (_props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {t} = useTranslation();
  const tableColumns = useAppSelector((state) => state.case.tableColumns);
  const isSaving = useAppSelector((state) => state.case.isSaving);

  const handleIncludeAllColumns = (e: any, table: any) => {
    e.stopPropagation();
    dispatch(includeAllColumnsInTable(table));
  };

  const handleIncludeSingleColumn = (e: any, table: any, column: any) => {
    e.stopPropagation();
    dispatch(includeSingleColumnInTable({table, column}));
  };

  const checkIfAllIncluded = (table: any) => {
    if (table.columns.length === 0) return false;
    let lengthOfIncludedColumns = table.columns.filter(
      (c: any) => c.included,
    ).length;
    return lengthOfIncludedColumns === table.columns.length;
  };

  const checkIfIndeterminate = (table: any) => {
    let lengthOfIncludedColumns = table.columns.filter(
      (c: any) => c.included,
    ).length;
    return (
      lengthOfIncludedColumns > 0 &&
      lengthOfIncludedColumns < table.columns.length
    );
  };

  return (
    <Card>
      <CardHeader
        title={t('pages.caseReportSettings.reportColumns')}
        titleTypographyProps={{variant: 'h6'}}
        subheader={t('pages.caseReportSettings.inOrExclude')}
        subheaderTypographyProps={{variant: 'subtitle2'}}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}>
          {tableColumns.map((table, tindex) => (
            <div key={tindex}>
              <ListItem
                className={classes.listItem}
                button
                divider
                dense
                onClick={() => dispatch(expandTable(table))}>
                <ListItemIcon>
                  {table.open ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checkIfAllIncluded(table)}
                  disabled={isSaving || table.columns.length === 0}
                  indeterminate={checkIfIndeterminate(table)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{'aria-labelledby': table.table_name}}
                  onClick={(e) => handleIncludeAllColumns(e, table)}
                />
                <ListItemText primary={`Table: ${table.table_name}`} />
              </ListItem>
              <Collapse in={table.open} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding>
                  {table.columns.length === 0 && (
                    <ListItem divider className={classes.nested}>
                      <ListItemText
                        className={classes.listItemText}
                        primary={`No columns available`}
                      />
                    </ListItem>
                  )}
                  {table.columns.map((column: any, cindex: number) => (
                    <ListItem
                      divider
                      className={classes.nested}
                      key={
                        table.table_name === 'questions'
                          ? column.question_id
                          : column.column_name
                      }>
                      <Checkbox
                        onClick={(e) =>
                          handleIncludeSingleColumn(e, table, column)
                        }
                        disabled={isSaving}
                        edge="start"
                        checked={column.included}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': column.column_name,
                        }}
                      />
                      <ListItemText
                        className={classes.listItemText}
                        primary={`${column.column_name}`}
                      />
                      <Input
                        value={column.caption}
                        placeholder={t('pages.caseReportSettings.caption')}
                        inputProps={{
                          'aria-label': 'description',
                        }}
                        className={classes.flexStretch}
                        onChange={(e) =>
                          dispatch(
                            changeCaption({
                              value: e.target.value,
                              tableIndex: tindex,
                              columnIndex: cindex,
                            }),
                          )
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default CaseReportSettingsColumnsCard;
