import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Theme,
} from '@mui/material';
import {createStyles, makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {changeShowOnlyLastCall} from '../caseSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(4),
    },
    cardActions: {
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
  }),
);

const CaseReportSettingsOtherCard = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {t} = useTranslation();

  const showOnlyLastCalls = useAppSelector(
    (state) => state.case.showOnlyLastCall,
  );
  const isSaving = useAppSelector((state) => state.case.isSaving);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={t('pages.caseReportSettings.otherSettings')}
        titleTypographyProps={{variant: 'h6'}}
        subheader={t('pages.caseReportSettings.description')}
        subheaderTypographyProps={{variant: 'subtitle2'}}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} container direction="column">
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnlyLastCalls || false}
                  disabled={isSaving}
                  onChange={() => dispatch(changeShowOnlyLastCall())}
                />
              }
              label={t('pages.caseReportSettings.showOnlyLastCall').toString()}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default CaseReportSettingsOtherCard;
