import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { push } from 'connected-react-router';
import { useAppDispatch } from '../hooks';
import { Route, Switch, useRouteMatch, useLocation } from 'react-router';
import DashboardMain from './DashboardMain';
import DashboardByCase from './DashboardByCase';
import {
  getCaseSuccessCount,
  getDashboardData,
  getEmployees,
  getOpenCases,
} from '../features/dashboard/dashboardSlice';
import DashboardByPm from './DashboardByPm';
import { useTranslation } from 'react-i18next';

interface LinkTabProps {
  label?: string;
  href: string;
  value: string;
}

const LinkTab = (props: LinkTabProps) => {
  const dispatch = useAppDispatch();
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        dispatch(push(props.href));
      }}
      {...props}
    />
  );
};

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getOpenCases());
    dispatch(getDashboardData());
    dispatch(getCaseSuccessCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={location.pathname} aria-label="dashboard-nav">
          <LinkTab label={t('pages.dashboard.main')} href={`${path}`} value="/dashboard" />
          <LinkTab label={t('pages.dashboard.byPm')} href={`${path}/by-pm`} value="/dashboard/by-pm" />
          <LinkTab label="By Case" href={`${path}/by-case`} value="/dashboard/by-case" />
        </Tabs>
      </Box>

      <Switch>
        <Route exact path={path} component={DashboardMain} />
        <Route path={`${path}/by-pm`} component={DashboardByPm} />
        <Route path={`${path}/by-case`} component={DashboardByCase} />
      </Switch>
    </>
  );
};

export default Dashboard;
