import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  Toolbar,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useEffect, useMemo, useState } from 'react';
import DashboardDataLoading from '../features/dashboard/components/DashboardDataLoading';
import NoDashboardData from '../features/dashboard/components/NoDashboardData';
import { getCompaniesCases, getDashboardDataByCase, setSelectedCase } from '../features/dashboard/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import Helpers from '../util/helpers';
import httpService from '../services/httpService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: '0 !important',
      '& > *': {
        marginRight: `${theme.spacing(2)} !important`,
      },
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold !important',
    },
    bold: {
      fontWeight: 'bold !important',
    },
    center: {
      textAlign: 'center',
    },
    table: {
      fontSize: '0.875rem',
      width: '100%',
      marginBottom: theme.spacing(2),
      border: '1px solid grey',
      borderCollapse: 'collapse',
      '& th, td': {
        border: '1px solid grey',
        padding: '1px 4px',
        wordWrap: 'break-word',
        maxWidth: 120,
      },
      '& thead': {
        color: '#000',
        textAlign: 'left',
      },
    },
    sumRow: {
      backgroundColor: '#fce5cd',
      fontWeight: 'bold',
    },
    chart: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
    orangeTable: {
      '& thead': {
        color: '#000',
        backgroundColor: '#fce5cd',
      },
      border: '2px solid orange !important',
    },
    blueTable: {
      '& thead': {
        color: '#000',
        backgroundColor: '#d0e0e3',
      },
      border: '2px solid blue !important',
    },
    greenTable: {
      '& thead': {
        color: '#000',
        backgroundColor: '#34a853',
      },
      border: '2px solid green !important',
    },
    greyTable: {
      backgroundColor: '#d9d9d9',
    },
  }),
);

const DashboardByCase = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dashboardDataByCaseLoading: boolean = useAppSelector((state) => state.dashboard.dashboardDataByCaseLoading);
  const selectedCase: any = useAppSelector((state) => state.dashboard.selectedCase);
  const dashboardDataByCase: any[] = useAppSelector((state) => state.dashboard.dashboardDataByCase);
  const openCases: any[] = useAppSelector((state) => state.dashboard.openCases);
  const byCaseData: any = useAppSelector((state) => state.dashboard.byCaseData);
  const companiesCases: any[] = useAppSelector((state) => state.dashboard.companiesCases);

  const dashboardDataLoading: boolean = useAppSelector((state) => state.dashboard.dashboardDataLoading);
  const employeesLoading: boolean = useAppSelector((state) => state.dashboard.employeesLoading);
  const openCasesLoading: boolean = useAppSelector((state) => state.dashboard.openCasesLoading);
  const caseSuccessCountLoading: boolean = useAppSelector((state) => state.dashboard.caseSuccessCountLoading);

  const [searchText, setSearchText] = useState('');
  const [cases, setCases] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  
  const isLoading =
    loading ||
    dashboardDataLoading ||
    employeesLoading ||
    openCasesLoading ||
    caseSuccessCountLoading ||
    dashboardDataByCaseLoading;

  const outboundCostFT = 1000;
  const outboundCostDFT = 672;
  const outboundCostPT = 560;

  const meetingCostFT = 1000;
  const meetingCostDFT = 672;
  const meetingCostPT = 560;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(searchText.length >= 2) {
        fetchCases();
      }
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchText]);

  useEffect(() => {
    setCases(openCases);
  }, [openCases]);

  const successCountSum = useMemo(() => {
    return dashboardDataByCase
      .map((row) => parseInt(row.successes_count || 0))
      .reduce((partialSum: any, a: any) => partialSum + a, 0);
  }, [dashboardDataByCase]);

  const outboundTarget = useMemo(() => {
    return byCaseData.workingHoursFT * outboundCostFT +
      byCaseData.workingHoursDFT * outboundCostDFT +
      byCaseData.workingHoursPT * outboundCostPT;
  }, [byCaseData]);

  const meetingTarget = useMemo(() => {
    return byCaseData.meetingHoursFT * meetingCostFT +
      byCaseData.meetingHoursDFT * meetingCostDFT +
      byCaseData.meetingHoursPT * meetingCostPT;
  }, [byCaseData]);

  const kpiPercentageValue = useMemo(() => {
    if (outboundTarget + meetingTarget == 0) {
      return 0;
    }

    if (!selectedCase.corrected_price) {
      return 0;
    }

    return parseFloat(
      (
        Math.round(
          (parseInt(selectedCase.corrected_price) * successCountSum) / (outboundTarget + meetingTarget) * 10000
        ) / 100
      ).toFixed(2),
    )
  }, [selectedCase, byCaseData, successCountSum, outboundTarget, meetingTarget]);


  const calcBusinessDays = (dDate1: Date, dDate2: Date) => {
    // input given as Date objects
    var iWeeks,
      iDateDiff,
      iAdjust = 0;
    if (dDate2 < dDate1) return -1; // error code if dates transposed
    var iWeekday1 = dDate1.getDay(); // day of week
    var iWeekday2 = dDate2.getDay();
    iWeekday1 = iWeekday1 === 0 ? 7 : iWeekday1; // change Sunday from 0 to 7
    iWeekday2 = iWeekday2 === 0 ? 7 : iWeekday2;
    if (iWeekday1 > 5 && iWeekday2 > 5) iAdjust = 1; // adjustment if both days on weekend
    iWeekday1 = iWeekday1 > 5 ? 5 : iWeekday1; // only count weekdays
    iWeekday2 = iWeekday2 > 5 ? 5 : iWeekday2;

    // calculate differnece in weeks (1000mS * 60sec * 60min * 24hrs * 7 days = 604800000)
    iWeeks = Math.floor((dDate2.getTime() - dDate1.getTime()) / 604800000);

    if (iWeekday1 < iWeekday2) {
      //Equal to makes it reduce 5 days
      iDateDiff = iWeeks * 5 + (iWeekday2 - iWeekday1);
    } else {
      iDateDiff = (iWeeks + 1) * 5 - (iWeekday1 - iWeekday2);
    }

    iDateDiff -= iAdjust; // take into account both days on weekend

    return iDateDiff + 1; // add 1 because dates are inclusive
  };

  useEffect(() => {
    dispatch(getDashboardDataByCase());
    dispatch(getCompaniesCases());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCase]);

  const fetchCases = async () => {
    setLoading(true);
    let params: any = {};
    if (searchText !== '') {
      params['name'] = `%${searchText}%`;
    }
    const response = await httpService.get('/dashboard/search-cases', { params });
    setCases(response.data);
    setLoading(false);
  };

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Autocomplete
          openOnFocus
          sx={{ m: 1, minWidth: 300 }}
          value={selectedCase}
          options={cases}
          onChange={(event, value) => {if(value) dispatch(setSelectedCase(value))}}
          getOptionLabel={(option) => option.case_name || ""}
          renderInput={(params) => (
            <TextField {...params} 
            onChange={(e) => {setSearchText(e.target.value)}}
            label="Case" variant="standard" />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.case_name}
              </li>
            );
          }}
        />
        {isLoading && <CircularProgress />}
      </Toolbar>
      {dashboardDataByCase.length !== 0 && !isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <table className={`${classes.greenTable} ${classes.table} ${classes.bold}`}>
              <thead>
                <tr>
                  <th colSpan={2}>專案 KPI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <Box sx={{ height: "200px", width: "100%"}}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        sx={{ width: "100%", textAlign: "center" }}
                        fontSize={30}
                      >
                        {kpiPercentageValue}%
                      </Typography>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>目標</td>
                  <td align="right">{Helpers.formatter.format(outboundTarget + meetingTarget)}</td>
                </tr>
                <tr>
                  <td>實際收入</td>
                  <td align="right">
                    {Helpers.formatter.format(
                      (selectedCase.corrected_price || 0) * successCountSum
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={`${classes.table} ${classes.greyTable}`}>
              <thead>
                <tr>
                  <th colSpan={3}>Calculation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>外撥目標_FT</td>
                  <td>{byCaseData.workingHoursFT}</td>
                  <td>{outboundCostFT}</td>
                </tr>
                <tr>
                  <td>外撥目標_DFT</td>
                  <td>{byCaseData.workingHoursDFT}</td>
                  <td>{outboundCostDFT}</td>
                </tr>
                <tr>
                  <td>外撥目標_PT</td>
                  <td>{byCaseData.workingHoursPT}</td>
                  <td>{outboundCostPT}</td>
                </tr>
                <tr className={classes.bold}>
                  <td colSpan={2} className={`${classes.center}`}>
                    外撥目標
                  </td>
                  <td>{outboundTarget}</td>
                </tr>
                <tr>
                  <td>會議目標_FT</td>
                  <td>{byCaseData.meetingHoursFT}</td>
                  <td>{meetingCostFT}</td>
                </tr>
                <tr>
                  <td>會議目標_DFT</td>
                  <td>{byCaseData.meetingHoursDFT}</td>
                  <td>{meetingCostDFT}</td>
                </tr>
                <tr>
                  <td>會議目標_PT</td>
                  <td>{byCaseData.meetingHoursPT}</td>
                  <td>{meetingCostPT}</td>
                </tr>
                <tr className={classes.bold}>
                  <td colSpan={2} className={`${classes.center}`}>
                    會議目標
                  </td>
                  <td>{meetingTarget}</td>
                </tr>
                <tr>
                  <td>績效單價</td>
                  <td>{selectedCase.corrected_price || 0}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12} sm={4}>
            <table className={`${classes.table} ${classes.orangeTable} ${classes.bold}`}>
              <thead>
                <tr>
                  <th colSpan={2}>專案進度</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress
                        variant="determinate"
                        size="200px"
                        value={
                          !selectedCase.primary_goal
                            ? 0
                            : Math.min(
                                (successCountSum /
                                  selectedCase.primary_goal) *
                                  100,
                                100,
                              )
                        }
                      />
                      <Typography position="absolute" fontSize={30}>
                        {parseFloat(
                          Math.round(
                            !selectedCase.primary_goal
                              ? 0
                              : Math.min(
                                  (successCountSum /
                                    selectedCase.primary_goal) *
                                    100,
                                  100,
                                ),
                          ).toFixed(2),
                        )}
                        %
                      </Typography>
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>尚缺</td>
                  <td>
                    {selectedCase.primary_goal
                      ? Math.max(
                          0,
                          selectedCase.primary_goal - successCountSum,
                        )
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td>已完成</td>
                  <td>{successCountSum}</td>
                </tr>
                <tr>
                  <td>剩餘工作天</td>
                  <td>{Math.max(0, calcBusinessDays(new Date(), new Date(selectedCase.end_date)) - 1)}</td>
                </tr>
                <tr>
                  <td>未來每天需完成</td>
                  <td>
                    {calcBusinessDays(new Date(), new Date(selectedCase.end_date)) - 1 > 0
                      ? parseFloat(
                          (
                            Math.round(
                              ((selectedCase.primary_goal
                                ? Math.max(
                                    0,
                                    selectedCase.primary_goal - successCountSum
                                  )
                                : 0) /
                                (calcBusinessDays(new Date(), new Date(selectedCase.end_date)) - 1)) *
                                100,
                            ) / 100
                          ).toFixed(2),
                        )
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td>預估每人每天可完成</td>
                  <td>
                    {parseFloat(
                      (
                        Math.round(
                          (calcBusinessDays(new Date(), new Date(selectedCase.end_date)) - 1 < 0
                            ? 0
                            : ([...dashboardDataByCase]
                                .sort((rowA, rowB) => {
                                  return new Date(rowB.log_date).getTime() - new Date(rowA.log_date).getTime();
                                })
                                .slice(0, 3)
                                .map((row) => {
                                  return parseFloat(row.successes_count || 0);
                                })
                                .reduce((partialSum: any, a: any) => partialSum + a, 0) /
                                [...dashboardDataByCase]
                                  .sort((rowA, rowB) => {
                                    return new Date(rowB.log_date).getTime() - new Date(rowA.log_date).getTime();
                                  })
                                  .slice(0, 3)
                                  .map((row) => {
                                    return parseFloat(row.working_hours || 0);
                                  })
                                  .reduce((partialSum: any, a: any) => partialSum + a, 0) || 0) * 6.5) * 100,
                        ) / 100
                      ).toFixed(2),
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={`${classes.table} ${classes.greyTable}`}>
              <thead>
                <tr>
                  <th colSpan={2}>Calculation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>專案目標</td>
                  <td>{selectedCase.primary_goal || 'none'}</td>
                </tr>
                <tr>
                  <td>結束日期</td>
                  <td>{selectedCase.end_date || 'none'}</td>
                </tr>
                <tr>
                  <td>過去三天總完成數</td>
                  <td>
                    {[...dashboardDataByCase]
                      .sort((rowA, rowB) => {
                        return new Date(rowB.log_date).getTime() - new Date(rowA.log_date).getTime();
                      })
                      .slice(0, 3)
                      .map((row) => {
                        return parseFloat(row.successes_count || 0);
                      })
                      .reduce((partialSum: any, a: any) => partialSum + a, 0)}
                  </td>
                </tr>
                <tr>
                  <td>過去三天總外撥時數</td>
                  <td>
                    {[...dashboardDataByCase]
                      .sort((rowA, rowB) => {
                        return new Date(rowB.log_date).getTime() - new Date(rowA.log_date).getTime();
                      })
                      .slice(0, 3)
                      .map((row) => {
                        return parseFloat(row.working_hours || 0);
                      })
                      .reduce((partialSum: any, a: any) => partialSum + a, 0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={12} sm={4}>
            <table className={`${classes.table} ${classes.blueTable}`}>
              <thead className={`${classes.bold}`}>
                <tr>
                  <th
                    colSpan={
                      3 + [...new Set(companiesCases.map((row) => row.english_name))].filter((tm) => tm !== '').length
                    }>
                    名單狀況
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={`${classes.bold}`}>
                  <td>狀態</td>
                  <td>總計</td>
                  {[...new Set(companiesCases.map((row) => row.english_name))]
                    .filter((tm) => tm !== '')
                    .map((tm) => {
                      return <td key={tm}>{tm}</td>;
                    })}
                  <td>No Name</td>
                </tr>
                {[...new Set(companiesCases.map((row) => row.status))]
                  .filter((status) => status !== '')
                  .map((status) => {
                    return (
                      <tr key={status}>
                        <td>{status}</td>
                        <td>{companiesCases.filter((row) => row.status === status).length}</td>
                        {[...new Set(companiesCases.map((row) => row.english_name))]
                          .filter((tm) => tm !== '')
                          .map((tm) => {
                            return (
                              <td key={tm}>
                                {
                                  companiesCases.filter((row) => row.status === status && row.english_name === tm)
                                    .length
                                }
                              </td>
                            );
                          })}
                        <td>
                          {companiesCases.filter((row) => row.status === status && row.english_name === '').length}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td>No status</td>
                  <td>{companiesCases.filter((row) => row.status === '').length}</td>
                  {[...new Set(companiesCases.map((row) => row.english_name))]
                    .filter((tm) => tm !== '')
                    .map((tm) => {
                      return (
                        <td key={tm}>
                          {companiesCases.filter((row) => row.status === '' && row.english_name === tm).length}
                        </td>
                      );
                    })}
                  <td>{companiesCases.filter((row) => row.status === '' && row.english_name === '').length}</td>
                </tr>
                <tr className={`${classes.bold}`}>
                  <td>Total</td>
                  <td>{companiesCases.length}</td>
                  {[...new Set(companiesCases.map((row) => row.english_name))]
                    .filter((tm) => tm !== '')
                    .map((tm) => {
                      return <td key={tm}>{companiesCases.filter((row) => row.english_name === tm).length}</td>;
                    })}
                  <td>{companiesCases.filter((row) => row.english_name === '').length}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      )}
      {isLoading && <DashboardDataLoading />}
      {dashboardDataByCase.length === 0 && !isLoading && <NoDashboardData />}
    </>
  );
};

export default DashboardByCase;
