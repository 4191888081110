import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {red} from '@mui/material/colors';
import {makeStyles} from '@mui/styles';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReportTemplate} from '../features/case/caseSlice';
import {useAppDispatch} from '../hooks';
import httpService from '../services/httpService';

const useStyles = makeStyles({
  selected: {
    '&.Mui-selected': {
      backgroundColor: red[50],
    },
  },
  list: {
    height: 400,
    overflow: 'auto',
  },
});

export interface ReportTemplateSelectDialogProps {
  open: boolean;
  onClose: () => void;
  caseId: string;
}

interface ReportTemplate {
  id: Number;
  created_at: Date;
  created_by: Number;
  case_id: Number;
  template_name: String;
  table_columns: Array<any>;
  show_only_last_call: Boolean;
}

const ReportTemplateSelectDialog = (props: ReportTemplateSelectDialogProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [reportTemplates, setReportTemplates] = useState<Array<ReportTemplate>>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  const {onClose, open, caseId} = props;

  const handleClose = () => {
    onClose();
  };

  const handleUseTemplate = () => {
    let reportTemplateSettings = reportTemplates[selectedIndex];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    dispatch(useReportTemplate({id: caseId, reportTemplateSettings}));
    handleClose();
  };

  const handleDeleteTemplate = async () => {
    console.log(reportTemplates[selectedIndex]);
    if (reportTemplates[selectedIndex]) {
      let id = reportTemplates[selectedIndex].id;
      await httpService.delete(`/report-templates/${id}`);
      await fetchReportTemplates();
      setSelectedIndex(-1);
    }
  };

  const fetchReportTemplates = async () => {
    setLoading(true);
    let response = await httpService.get('/report-templates/');
    setReportTemplates(response.data);
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchReportTemplates();
    }
  }, [open]);

  return (
    <Dialog onClose={handleClose} aria-labelledby="create-report-template-title" open={open}>
      <DialogTitle id="create-report-template-title">{t('dialog.reportTemplateSelect.useTemplate')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialog.reportTemplateSelect.useTemplateDescription')}</DialogContentText>
        {loading && <CircularProgress />}
        {!loading && (
          <List aria-label="report template list" className={classes.list}>
            {reportTemplates.length === 0 && (
              <ListItem>
                <ListItemText primary="No Report Templates available" />
              </ListItem>
            )}
            {reportTemplates.map((template: any, index: number) => (
              <ListItem
                classes={{selected: classes.selected}}
                key={template.id}
                button
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}>
                <ListItemText primary={template.template_name} />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteTemplate} color="primary" disabled={selectedIndex === -1}>
          {t('dialog.reportTemplateSelect.delete')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('dialog.reportTemplateSelect.cancel')}
        </Button>
        <Button variant="contained" disabled={selectedIndex === -1} onClick={handleUseTemplate} color="primary">
          {t('dialog.reportTemplateSelect.useTemplate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportTemplateSelectDialog;
