import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
  Theme,
  Popover,
  TextField,
  Badge,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { DataGrid, getGridDateOperators, getGridStringOperators, GridColDef, GridSortModel } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import { useAppDispatch, useAppSelector } from '../hooks';
import httpService from '../services/httpService';
import FlexStretch from '../components/FlexStretch';
import GetAppIcon from '@mui/icons-material/GetApp';
import DescriptionIcon from '@mui/icons-material//Description';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import Helpers from '../util/helpers';
import { getCaseData } from '../features/case/caseSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    toolbar: {
      padding: 0,
      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
    card: {
      marginTop: theme.spacing(4),
    },
    cardContent: {
      padding: 0,
    },
    cardActions: {
      justifyContent: 'flex-end',
      padding: theme.spacing(2),
    },
    nested: {
      paddingLeft: theme.spacing(12),
      paddingTop: 0,
      paddingBottom: 0,
    },
    flexStretch: {
      flex: 1,
    },
    listItemText: {
      width: 400,
      maxWidth: 400,
    },
    filterCard: {
      padding: theme.spacing(1),
      gap: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
    },
    filterCardRow: {
      display: 'flex',
      gap: theme.spacing(1),
    },
  }),
);

function CustomNoRowsOverlay() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
      {t('pages.caseReport.noCalls')}
    </Box>
  );
}

type Params = {
  id: string,
};

const downloadOptions = [
  { path: 'calls', name: 'Calls' },
  { path: 'companies', name: 'Companies' },
  { path: 'companies_cases', name: 'Companies Cases' },
  { path: 'contacts', name: 'Contacts' },
  { path: 'contacts_cases', name: 'Contacts_cases' },
  { path: 'questions', name: 'Questions' },
  { path: 'telemarketers', name: 'Employees/Telemarketers' },
];

const filterableFields: GridColDef[] = [
  {
    field: 'companies.id',
    sortable: true,
    filterable: true,
    type: 'string',
    disableColumnMenu: false,
    filterOperators: getGridStringOperators().filter((operator) => {
      return operator.value === 'equals';
    }),
  },
  {
    field: 'calls.call_date',
    sortable: true,
    filterable: true,
    type: 'date',
    disableColumnMenu: false,
    filterOperators: getGridDateOperators().filter((operator) => {
      return operator.value === 'is';
    }),
  },
  {
    field: 'employees.english_name',
    sortable: true,
    filterable: true,
    type: 'string',
    disableColumnMenu: false,
    filterOperators: getGridStringOperators().filter((operator) => {
      return operator.value === 'equals';
    }),
  },
];

const CaseReport = () => {
  const { id } = useParams<Params>();
  const dispatch = useAppDispatch();
  const currentCase = useAppSelector((state) => state.case.currentCase);
  const currentUserRole = useAppSelector((state) => state.user?.role);
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState('');
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [downloading, setDownloading] = useState(false);
  const [anchorElDownload, setAnchorElDownload] = useState<null | HTMLElement>(null);
  const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(null);
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>();
  const open = Boolean(anchorElDownload);
  const { t } = useTranslation();

  const [filters, setFilters] = useState([
    {
      field: 'companies.id',
      label: 'companies.id',
      value: '',
      type: 'string',
      included: false,
    },
    {
      field: 'calls.call_date',
      label: 'calls.call_date',
      value: '',
      type: 'date',
      included: false,
    },
    {
      field: 'employees.english_name',
      label: 'employees.english_name',
      value: '',
      type: 'string',
      included: false,
    },
  ]);

  const classes = useStyles();

  const fetchReport = async () => {
    setLoading(true);
    try {
      let params: any = {
        page: page + 1,
        size: pageSize,
      };

      filters.forEach((column) => {
        if (column.value) {
          params = {
            ...params,
            [column.field.replace('.', '_')]: column.type === 'date' ? Helpers.formatDate(column.value) : column.value,
          };
        }
      });

      if (sortModel && sortModel.length > 0) {
        params = {
          ...params,
          sort_by: sortModel[0].field,
          sort_direction: sortModel[0].sort,
        };
      }
      let response = await httpService.get('/cases/' + id + '/report', {
        params,
      });
      setRows(response.data.items);
      setRowCount(response.data.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  const fetchReportSettings = async () => {
    try {
      let response = await httpService.get('/cases/' + id + '/case-settings/report');

      if (!response.data) return;

      let columns: GridColDef[] = [];

      let table_columns = response.data.table_columns;

      if (!table_columns) return;

      let newFilters = [...filters];

      if (table_columns.length > 0) {
        table_columns.forEach((table: any) => {
          table.columns.forEach((column: any) => {
            if (column.included) {
              const foundFilterIndex = newFilters.findIndex((nf) => nf.field === column.column_name);
              if (foundFilterIndex >= 0) {
                newFilters[foundFilterIndex].included = true;
                newFilters[foundFilterIndex].label = column.caption ? column.caption : column.column_name;
              }
              columns.push({
                field: column.column_name,
                headerName: column.caption ? column.caption : column.column_name,
                width: 180,
                sortable: filterableFields.find((fs) => fs.field === column.column_name)?.sortable || false,
                filterable: filterableFields.find((fs) => fs.field === column.column_name)?.filterable || false,
                type: filterableFields.find((fs) => fs.field === column.column_name)?.type || undefined,
                disableColumnMenu: filterableFields.find((fs) => fs.field === column.column_name)
                  ? filterableFields.find((fs) => fs.field === column.column_name)?.disableColumnMenu
                  : true,
                hideable: false,
                filterOperators:
                  filterableFields.find((fs) => fs.field === column.column_name)?.filterOperators || undefined,
              });
            }
          });
        });
        setFilters(newFilters);
        setColumns(columns);
      }
    } catch (err) {
      alert(err);
    }
  };

  const downloadFile = (path: string) => {
    setDownloading(true);
    let params: any = {};

    filters.forEach((column) => {
      if (column.value) {
        params = {
          ...params,
          [column.field.replace('.', '_')]: column.type === 'date' ? Helpers.formatDate(column.value) : column.value,
        };
      }
    });

    if (sortModel && sortModel.length > 0) {
      params = {
        ...params,
        sort_by: sortModel[0].field,
        sort_direction: sortModel[0].sort,
      };
    }
    httpService
      .get(`/cases/${id}/${path}/download`, { params, responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const currentDateStr = moment().format('YYYYMMDD');
        link.href = url;
        link.setAttribute('download', `${currentCase.case_name}_${path}_${currentDateStr}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        alert(error);
      });
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const handleSortChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const fetchCaseQuestionnaires = async () => {
    try {
      let response = await httpService.get('/questionnaires/', {
        params: { case_id: id },
      });
      if (response.data && response.data.length > 0) {
        setSelectedQuestionnaireId(response.data[0].id);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleClickDownload = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setAnchorElDownload(null);
    if (value !== 'backdropClick') downloadFile(value);
  };

  const redirectToEmos = (event: any) => {
    const url = process.env.REACT_APP_EMOS_URL;
    const mainUrl = url + '/#!/manage/';
    const companyId = event.row['company_id'] || event.row['companies.id'];
    const redirectUrl = id + '-' + selectedQuestionnaireId + '-' + companyId + '/questionnaires';
    window.open(mainUrl + redirectUrl, '_blank')?.focus();
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setAnchorElFilter(null);
  };
  const filterOpen = Boolean(anchorElFilter);
  const filterPopoverId = filterOpen ? 'filter-popover' : undefined;

  const clearFilter = (index: number) => {
    let newArr = [...filters];
    newArr[index].value = '';

    setFilters(newArr);
  };

  const updateFilterValue = (index: number, value: string | null) => {
    let newArr = [...filters];
    newArr[index].value = value || '';

    setFilters(newArr);
  };

  useEffect(() => {
    dispatch(getCaseData(id));
    fetchReportSettings();
    fetchCaseQuestionnaires();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchReport();
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, sortModel]);

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <IconButton
          aria-label="back"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(push('/cases/'));
          }}>
          <ArrowBackIcon />
        </IconButton>
        {currentCase && <Typography variant="h5">{currentCase.case_name}</Typography>}
        <FlexStretch />
        {downloading && <CircularProgress />}
        <Tooltip title={t('pages.caseReport.filters').toString()}>
          <IconButton aria-describedby={filterPopoverId} onClick={handleClickFilter}>
            <Badge badgeContent={filters.filter((column) => column.value !== '').length} color="primary">
              <FilterAltIcon color="action" />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          id={filterPopoverId}
          open={filterOpen}
          anchorEl={anchorElFilter}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <div className={classes.filterCard}>
            {filters
              .filter((column) => column.included)
              .map((column, index) => (
                <div key={column.field} className={classes.filterCardRow}>
                  <IconButton onClick={() => clearFilter(index)}>
                    <CloseIcon />
                  </IconButton>
                  <TextField
                    label={t('pages.caseReport.column').toString()}
                    value={column.label}
                    InputProps={{
                      disabled: true,
                    }}
                    variant="standard"
                  />
                  {column.type === 'string' && (
                    <TextField
                      label={t('pages.caseReport.value').toString()}
                      variant="standard"
                      value={column.value}
                      onChange={(e) => updateFilterValue(index, e.target.value)}
                      disabled={loading}
                    />
                  )}
                  {column.type === 'date' && (
                    <DatePicker
                      mask="____-__-__"
                      label={t('pages.caseReport.value').toString()}
                      value={column.value}
                      inputFormat="yyyy-MM-dd"
                      onChange={(newValue: any) => {
                        updateFilterValue(index, newValue);
                      }}
                      disabled={loading}
                      renderInput={(params: any) => <TextField variant="standard" {...params} />}
                    />
                  )}
                </div>
              ))}
          </div>
        </Popover>
        {currentUserRole === 'pm' && (
          <Tooltip title={t('pages.caseReport.goToSettings').toString()}>
            <IconButton
              aria-label="go-to-settings"
              id="go-to-settings-button"
              aria-haspopup="true"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(push(`/cases/${id}/report/settings`));
              }}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        )}
        {rows.length > 0 && (
          <>
            {currentUserRole === 'pm' && (
              <>
                <Tooltip title={t('pages.caseReport.downloadTables').toString()}>
                  <IconButton
                    aria-label="download-tables"
                    id="download-tables-button"
                    aria-haspopup="true"
                    onClick={handleClickDownload}
                    disabled={downloading}>
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="download-menu"
                  anchorEl={anchorElDownload}
                  keepMounted
                  open={open}
                  onClose={handleCloseDownload}>
                  {downloadOptions.map((option) => (
                    <MenuItem
                      key={option.path}
                      value={option.path}
                      onClick={(event) => handleCloseDownload(event, option.path)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
            <Tooltip title={t('pages.caseReport.downloadReport').toString()}>
              <IconButton
                aria-label="download"
                id="download-button"
                aria-haspopup="true"
                onClick={() => downloadFile('report')}
                disabled={downloading}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Toolbar>
      <TableContainer component={Paper}>
        <div style={{ height: 800, width: '100%' }}>
          <DataGrid
            localeText={{
              columnMenuFilter: t('dataGrid.columnMenuFilter'),
              columnMenuHideColumn: t('dataGrid.columnMenuHideColumn'),
              columnMenuShowColumns: t('dataGrid.columnMenuShowColumns'),
              columnMenuSortAsc: t('dataGrid.columnMenuSortAsc'),
              columnMenuSortDesc: t('dataGrid.columnMenuSortDesc'),
              columnMenuUnsort: t('dataGrid.columnMenuUnsort'),
              columnsPanelHideAllButton: t('dataGrid.columnsPanelHideAllButton'),
              columnsPanelShowAllButton: t('dataGrid.columnsPanelShowAllButton'),
              columnsPanelTextFieldLabel: t('dataGrid.columnsPanelTextFieldLabel'),
              columnsPanelTextFieldPlaceholder: t('dataGrid.columnsPanelTextFieldPlaceholder'),
              filterOperatorContains: t('dataGrid.filterOperatorContains'),
              filterOperatorEndsWith: t('dataGrid.filterOperatorEndsWith'),
              filterOperatorEquals: t('dataGrid.filterOperatorEquals'),
              filterOperatorIs: t('dataGrid.filterOperatorIs'),
              filterOperatorIsEmpty: t('dataGrid.filterOperatorIsEmpty'),
              filterOperatorIsNotEmpty: t('dataGrid.filterOperatorIsNotEmpty'),
              filterOperatorStartsWith: t('dataGrid.filterOperatorStartsWith'),
              filterPanelColumns: t('dataGrid.filterPanelColumns'),
              filterPanelInputLabel: t('dataGrid.filterPanelInputLabel'),
              filterPanelInputPlaceholder: t('dataGrid.filterPanelInputPlaceholder'),
              filterPanelOperators: t('dataGrid.filterPanelOperators'),
            }}
            paginationMode="server"
            onPageChange={handlePageChange}
            // filterMode="server"
            // onFilterModelChange={handleFilterChange}
            sortingMode="server"
            onSortModelChange={handleSortChange}
            loading={loading}
            rows={rows}
            columns={columns}
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            rowsPerPageOptions={[pageSize]}
            disableSelectionOnClick
            disableColumnFilter
            density="compact"
            onRowClick={(e) => redirectToEmos(e)}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </div>
      </TableContainer>
    </>
  );
};

export default CaseReport;
