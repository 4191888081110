import { DatePicker } from '@mui/lab';
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import DashboardDataLoading from '../features/dashboard/components/DashboardDataLoading';
import NoDashboardData from '../features/dashboard/components/NoDashboardData';
import {
  getDashboardData,
  setSelectedCase,
  setSelectedDate,
  setSelectedPm,
} from '../features/dashboard/dashboardSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: '0 !important',
      '& > *': {
        marginRight: `${theme.spacing(2)} !important`,
      },
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      textAlign: 'center',
      fontWeight: 'bold !important',
    },
    table: {
      tableLayout: 'fixed',
      fontSize: '0.875rem',
      width: '100%',
      marginBottom: theme.spacing(4),
      border: '1px solid lightgrey',
      borderCollapse: 'collapse',
      '& th, td': {
        border: '1px solid lightgrey',
        padding: '1px 4px',
        wordWrap: 'break-word',
        maxWidth: 120,
      },
      '& thead': {
        textAlign: 'left',
        backgroundColor: '#fce5cd',
      },
    },
    sumRow: {
      backgroundColor: '#fce5cd',
      fontWeight: 'bold',
    },
    chart: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  }),
);

const DashboardByPm = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const dashboardDataLoading: boolean = useAppSelector((state) => state.dashboard.dashboardDataLoading);
  const employeesLoading: boolean = useAppSelector((state) => state.dashboard.employeesLoading);
  const openCasesLoading: boolean = useAppSelector((state) => state.dashboard.openCasesLoading);
  const caseSuccessCountLoading: boolean = useAppSelector((state) => state.dashboard.caseSuccessCountLoading);
  const selectedDate: string = useAppSelector((state) => state.dashboard.selectedDate);
  const pms: any[] = useAppSelector((state) => state.dashboard.pms);
  const selectedPm: any = useAppSelector((state) => state.dashboard.selectedPm);
  const dashboardData: any[] = useAppSelector((state) => state.dashboard.dashboardData);
  const openCases: any[] = useAppSelector((state) => state.dashboard.openCases);

  const isLoading = dashboardDataLoading || employeesLoading || openCasesLoading || caseSuccessCountLoading;

  const handleChangeSelectedDate = (date: Date | null) => {
    if (date) {
      dispatch(setSelectedDate(date.toString()));
      dispatch(getDashboardData());
    }
  };

  const handleChangeSelectedPm = (event: any) => {
    dispatch(setSelectedPm(event.target.value));
  };

  const goToCaseView = (openCase: any) => {
    dispatch(setSelectedCase(openCase));
    history.push('/dashboard/by-case');
  };

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <DatePicker
          mask="____-__-__"
          label={t('pages.dashboard.date').toString()}
          value={selectedDate}
          inputFormat="yyyy-MM-dd"
          onChange={handleChangeSelectedDate}
          disabled={isLoading}
          renderInput={(params: any) => <TextField variant="standard" {...params} />}
        />
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="selected-pm-label">PM</InputLabel>
          <Select
            labelId="selected-pm-label"
            id="pm-select"
            value={selectedPm}
            onChange={handleChangeSelectedPm}
            disabled={isLoading}>
            {pms.map((pm) => (
              <MenuItem key={pm.id} value={pm}>
                {pm.english_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isLoading && <CircularProgress />}
      </Toolbar>
      {openCases.filter((openCase) => openCase.pm_id === selectedPm.id).length > 0 &&
        dashboardData.length > 0 &&
        !isLoading && (
          <Grid container>
            <Grid item xs={12}>
              {openCases
                .filter((openCase) => openCase.pm_id === selectedPm.id)
                .filter((openCase) => dashboardData.filter((row) => row.case_id === openCase.id).length > 0)
                .sort((caseA, caseB) => caseB.id - caseA.id)
                .map((openCase) => (
                  <div key={openCase.id}>
                    <Typography
                      gutterBottom
                      component="div"
                      className={classes.title}
                      onClick={() => goToCaseView(openCase)}
                      style={{ cursor: 'pointer' }}>
                      {openCase.case_name}
                    </Typography>
                    <table className={`${classes.table}`}>
                      <thead>
                        <tr>
                          <th align="center">TM</th>
                          <th align="center">當日完成數</th>
                          <th align="center">當日達標率</th>
                          <th align="center">總完成數</th>
                          <th align="center">待追蹤</th>
                          <th align="center">考慮中</th>
                          <th align="center">新名單</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData
                          .filter((row) => row.case_id === openCase.id)
                          .map((row) => (
                            <tr key={row.telemarketer_id + row.case_id}>
                              <td align="center">{row.telemarketer_name}</td>
                              <td align="center">{row.successes_count || 0}</td>
                              <td align="center">{row.daily_goal_percent || 0}</td>
                              <td align="center">{row.successes_cumul || 0}</td>
                              <td align="center">{row.status_traced_count}</td>
                              <td align="center">{row.status_under_consideration_count}</td>
                              <td align="center">{row.status_new_list_count}</td>
                            </tr>
                          ))}
                        <tr className={classes.sumRow}>
                          <td align="center">總計</td>
                          <td align="center">
                            {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => row.successes_count || 0)
                              .reduce((partialSum, a) => partialSum + a, 0)}
                          </td>
                          <td align="center">
                            {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => row.daily_goal_percent || 0)
                              .reduce((partialSum, a) => partialSum + a, 0)}
                          </td>
                          <td align="center">
                            {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => row.successes_cumul || 0)
                              .reduce((partialSum, a) => partialSum + a, 0)}
                          </td>
                          <td align="center">
                            {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => row.status_traced_count || 0)
                              .reduce((partialSum, a) => partialSum + a, 0)}
                          </td>
                          <td align="center">
                            {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => row.status_under_consideration_count || 0)
                              .reduce((partialSum, a) => partialSum + a, 0)}
                          </td>
                          <td align="center">
                            {dashboardData
                              .filter((row) => row.case_id === openCase.id)
                              .map((row) => row.status_new_list_count || 0)
                              .reduce((partialSum, a) => partialSum + a, 0)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))}
            </Grid>
          </Grid>
        )}
      {isLoading && <DashboardDataLoading />}
      {(openCases.filter((openCase) => openCase.pm_id === selectedPm.id).length === 0 || dashboardData.length === 0) &&
        !isLoading && <NoDashboardData />}
    </>
  );
};

export default DashboardByPm;
