import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import httpService from '../../services/httpService';

interface Form {
  email: string;
  password: string;
}

export const getAccessToken = createAsyncThunk(
  'user/getAccessToken',
  async (form: Form, thunkAPI) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('username', form.email);
      bodyFormData.append('password', form.password);
      const response = await httpService.post(
        '/login/access-token',
        bodyFormData,
      );
      let data = response.data;
      if (response.status === 200) {
        localStorage.setItem('token', data.access_token);
        return data.access_token;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e: any) {
      console.log('Error', e.response.data);
    }
  },
);

export const testAccessToken = createAsyncThunk(
  'user/testAccessToken',
  // if you type your function argument here
  async (thunkAPI) => {
    const response = await httpService.post('/login/test-token');
    return response.data;
  },
);

const initialState = {
  email: '',
  role: '',
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
  isLoading: false,
  isAuthenticated: false,
  isChecked: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessToken.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(getAccessToken.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isAuthenticated = true;
      return state;
    });
    builder.addCase(getAccessToken.rejected, (state, action) => {
      state.isFetching = false;
      return state;
    });

    builder.addCase(testAccessToken.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(testAccessToken.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isChecked = true;
      state.isAuthenticated = true;
      state.email = action.payload.email;
      state.role = action.payload.group;
      return state;
    });
    builder.addCase(testAccessToken.rejected, (state, action) => {
      state.isFetching = false;
      state.isChecked = true;
      state.isAuthenticated = false;
      return state;
    });
  },
});

export const {logout} = userSlice.actions;

export default userSlice.reducer;
